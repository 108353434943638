<template>
  <div class="tv">
    <PageTitle title="TV-leverantörer"/>
    <CardsGrid>
      <Card
        v-if="false"
        title="AllTele"
        img="alltele"
        link="https://foretag.alltele.se">
        <div>AllTele levererar bredband, telefoni och TV.</div>
      </Card>
      <Card
        title="Boxer"
        img="boxerlogo"
        link="https://boxer.se"
        link-offer="https://boxer.se">
        <div>Levererar TV</div>
      </Card>
      <Card
        title="Telenor"
        img="telenor"
        link="https://telenor.se"
        phone="020 - 222 222">
        <div>Levererar bredband, telefoni och tv</div>
      </Card>
      <Card
        title="Telia"
        img="telia"
        link="https://telia.se/framtidensnat"
        :custom-content="true">
        <div>
          <div style="margin-bottom: 10px;">Levererar telefoni, TV, bredband samt fiber och växellösningar.</div>
          <div>Privaterbjudande: <a href="https://telia.se/privat/aktuellt/framtidensnat-privat" target="_blank">Framtidens
            nät
            Privat</a></div>
          <div>Tel: 020 - 55 11 69</div>
          <div> Företagserbjudande: <a href="https://telia.se/foretag/aktuellt/framtidensnat-foretag"
                                       target="_blank">Framtidens nät
            Företag</a></div>
          <div>Tel: 020-30 50 50</div>
        </div>
      </Card>
      <Card
        v-if="false"
        title="Universal"
        img="universal"
        link="https://universal.se">
        <div>AllTele levererar bredband, telefoni och TV</div>
      </Card>
      <Card
        v-if="false"
        title="Viasat"
        img="viasat"
        link="https://viasat.se"
        link-offer="https://viasat.se/produkter/parabol/nykund">
        <div>Levererar TV, bredband och telefoni.</div>
      </Card>
    </CardsGrid>
  </div>
</template>
<script>
import PageTitle from '@/components/PageTitle'
import CardsGrid from '@/components/CardsGrid'
import Card from '@/components/Card'

export default {
  components: {
    CardsGrid,
    Card,
    PageTitle
  }
}
</script>
